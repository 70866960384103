import { Button, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import { navigate } from 'gatsby';
import {
  action,
  computed,
  observable,
  runInAction
  } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Campus from '../images/campus.png';
import theme from '../theme';

const api = axios.create({
  baseURL: `${process.env.GATSBY_CAMPUS_API_URL}`,
});

const DemoPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 40;
  width: 100%;
  margin-top: 30px;
`;

const Title = styled(Typography)`
  &&&& {
    font-weight: 500;
    font-family: Quicksand;
    color: ${theme.palette.blue.main};
    font-size: 22px;
    ${theme.breakpoints.down('sm')} {
      font-size: 18px;
      padding: 0;
    }
  }
`;

const Wrapper = styled.form`
  max-width: 400px;
  width: 100%;
  margin-top: 40px;
`;

const MAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@observer
class Demo extends React.Component {
  @observable
  public email: string = '';

  @observable
  public dirty: boolean = true;

  @observable
  public sent: boolean = false;

  @action
  public handleEmailUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.email = e.target.value;
    this.dirty = false;
  }

  @computed
  get error() {
    return !this.dirty && !MAIL_REGEX.test(this.email);
  }

  public onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await api.get('/send_demo_email/', {
      params: { email: this.email },
    });

    runInAction(() => {
      this.sent = true;
    });

    navigate('/demo_follow');

    return false;
  }

  public render() {
    return (
      <Layout isCreatePage>
        <DemoPage>
          <Wrapper onSubmit={this.onSubmit}>
            <Title>Accéder gratuitement à notre démonstrateur</Title>
            <Typography
              align="left"
              style={{
                fontSize: '17px',
                color: theme.palette.blue.main,
                fontWeight: 300,
              }}
            >
              Vous recevrez par mail un lien d'accès dans la minute.
            </Typography>
            {!this.sent && (
              <>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Email"
                  autoFocus
                  placeholder="exemple@entreprise.com"
                  value={this.email}
                  variant="outlined"
                  onChange={this.handleEmailUpdate}
                  style={{ margin: '30px 0' }}
                  error={this.error}
                  helperText={
                    this.error ? 'Votre adresse email est invalide' : ''
                  }
                />
                <div style={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '20px', padding: '0 40px' }}
                    type="submit"
                    disabled={this.dirty || this.error}
                  >
                    Envoyer le mail
                  </Button>
                </div>
              </>
            )}
            <img src={Campus} style={{ width: '100%', marginTop: '70px' }} />
          </Wrapper>
        </DemoPage>
      </Layout>
    );
  }
}

export default Demo;
